/**
 * Job description
 *
 * @author David Faber
 * @author Ahmed Al-Yasiri
 * @copyright (c) Peritus Webdesign GmbH
 **/

// NPM
import React from 'react';
import PropTypes from 'prop-types';

// Peritus
import {Translate} from 'utilities';
import Block, {BlockShape} from 'components/Block';

// Styles
import * as styles from './job-description.module.scss';

// Assets
import ClockIcon from './clock.inline.svg';
import PinIcon from './pin.inline.svg';

const JobDescription = ({
                            responsibilities,
                            requirements,
                            benefits,
                            employmentLocation,
                            employmentType,
                            title,
                            titleAddendum,
                        }) => (
    <Block className={styles.jobPage}>
        <h2 className={styles.mainTitle}>
            <strong>{title} </strong>
            {titleAddendum}
        </h2>
        <div className={styles.jobInfo}>
            <span className={styles.infoItem}>
                <PinIcon className={styles.heroSvg}/>
                {employmentLocation}
            </span>
            <span className={styles.infoItem}>
                <ClockIcon className={styles.heroSvg}/>
            {employmentType}
            </span>
        </div>
        <div className={styles.responsibilities}>
            <h3 className={styles.title}>{Translate('jobDescription')}</h3>
            <div dangerouslySetInnerHTML={{__html: responsibilities}}/>
        </div>
        <div className={styles.requirements}>
            <h3 className={styles.title}>{Translate('jobRequirements')}</h3>
            <div dangerouslySetInnerHTML={{__html: requirements}}/>
        </div>
        <div className={styles.benefits}>
            <h3 className={styles.title}>{Translate('jobBenefits')}</h3>
            <div dangerouslySetInnerHTML={{__html: benefits}}/>
        </div>
    </Block>
);

JobDescription.propTypes = {
    ...BlockShape,
    responsibilities: PropTypes.string.isRequired,
    requirements: PropTypes.string.isRequired,
    benefits: PropTypes.string.isRequired,
    employmentLocation: PropTypes.string.isRequired,
    employmentType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    titleAddendum: PropTypes.string,
};

export default JobDescription;
