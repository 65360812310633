/**
 * Our blog-post-page
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
// NPM
import React from 'react';
import PropTypes from 'prop-types';
import {JsonLd} from 'react-schemaorg';

// Peritus
import Seo, {SeoShape} from 'components/Seo';
import Header from 'components/Header';
import Footer from 'components/Footer';
import {LangShape} from 'components/Languages';
import {JobDescription} from 'components/Job';
import Hero from '../../../blocks/Hero';

const Job = ({data, Blocks}) => {
    const {
        title,
        date,
        uri,
        seo,
        language,
        translations,
        acf: {
            hero,
            titleAddendum,
            employmentType,
            startDate,
            employmentLocation,
            requirements,
            responsibilities,
            benefits,
            pagebuilder: {
                layouts,
            },
            jobLocation,
            contactPoint,
            jobSettings,
        },
    } = data;

    return (<>
        <Seo seoData={seo} translations={translations} language={language} url={uri}/>
        <Header siteTitle={seo.title} translations={translations} language={language}/>
        <main>
            <Hero {...hero}/>
            <JobDescription
                title = {title}
                titleAddendum = {titleAddendum}
                requirements={requirements}
                responsibilities={responsibilities}
                benefits={benefits}
                employmentType={employmentType}
                startDate={startDate}
                employmentLocation={employmentLocation}
            />
            {layouts && layouts.map((block, index) => (<Blocks key={index} block={block}/>))}
            <JsonLd item={{
                '@context': 'http://schema.org',
                '@type': 'JobPosting',
                "title": title,
                "description": responsibilities,
                "validThrough": jobSettings.validthrough,
                "datePosted": date,
                "employmentType": employmentType,
                "hiringOrganization": {
                    "@type": "Organization",
                    "name": contactPoint.name,
                    "email": contactPoint.email,
                    "address": {
                        "@type": "PostalAddress",
                        "addressLocality": contactPoint.city,
                        "streetAddress": contactPoint.street,
                        "postalCode": contactPoint.postalcode,
                    },
                },
                "jobLocation": {
                    "@type": "Place",
                    "address": {
                        "@type": "PostalAddress",
                        "addressCountry": jobLocation.country,
                        "addressLocality": jobLocation.city,
                        "streetAddress": jobLocation.street,
                        "postalCode": jobLocation.postalcode,
                        "addressRegion": jobLocation.region,
                    },
                },
                "baseSalary": {
                    "@type": "MonetaryAmount",
                    "currency": jobSettings.currency,
                    "value": {
                        "@type": "QuantitativeValue",
                        "value": jobSettings.value,
                        "unitText": jobSettings.unittext,
                    }
                }
            }}
            />
        </main>
        <Footer/>
    </>);
};

Job.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string.isRequired,
        uri: PropTypes.string.isRequired,
        seo: PropTypes.shape(SeoShape).isRequired,
        ...LangShape,
        acf: PropTypes.shape({
            hero: PropTypes.shape(Hero.propTypes),
            titleAddendum: PropTypes.string,
            employmentType: PropTypes.string.isRequired,
            startDate: PropTypes.string.isRequired,
            jobLocation: PropTypes.shape({
                city: PropTypes.string.isRequired,
                country: PropTypes.string.isRequired,
                postalcode: PropTypes.string.isRequired,
                street: PropTypes.string,
                region: PropTypes.string.isRequired,
            }),
            contactPoint: PropTypes.shape({
                city: PropTypes.string.isRequired,
                email: PropTypes.string.isRequired,
                street: PropTypes.string.isRequired,
                postalcode: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            }),
            jobSettings: PropTypes.shape({
                validthrough: PropTypes.string,
                value: PropTypes.string,
                currency: PropTypes.string.isRequired,
                unittext: PropTypes.string,
            }),
            employmentLocation: PropTypes.string.isRequired,
            requirements: PropTypes.string.isRequired,
            responsibilities: PropTypes.string.isRequired,
            benefits: PropTypes.string,
            pagebuilder: PropTypes.shape({
                layouts: PropTypes.array.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,

};

export default Job;
